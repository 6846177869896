// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

// Common
require("@rails/ujs").start();
require("turbolinks").start();
require("@rails/activestorage").start();
require("jquery");
require("xhr2");
require("xmlhttprequest");
require("codebird");
require("dot");

// Highcharts
import Highcharts from 'highcharts';
require("highcharts/modules/data")(Highcharts);
require("highcharts/modules/map")(Highcharts);
window.Highcharts = Highcharts;

// CookiesEuBanner
import Cookies from 'js-cookie';
window.Cookies = Cookies;

// Custom
require("./custom/bootstrap.min");
require("./custom/oeitimeline");
require("./custom/oei");
require("./custom/maps/world_es");
require("./custom/maps/world_pt");
require("./custom/clickable-row");
require("./custom/dropdown-menu");
require("./custom/cookies_eu")
