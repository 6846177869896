//= require js.cookie
'use strict';

var windowIsTurbolinked = 'Turbolinks' in window;

var cookiesEu = {
  init: function() {
    var cookiesEuOKButton = document.querySelector('.js-cookies-eu-ok');
    var cookiesEuOKButton2 = document.querySelector('.js-cookies-eu-ok-2');
    var cookiesEuOKButton3 = document.querySelector('.js-cookies-eu-ok-3');

    if (cookiesEuOKButton) {
      this.addListener(cookiesEuOKButton);
      // clear turbolinks cache so cookie banner does not reappear
      windowIsTurbolinked && window.Turbolinks.clearCache();
    }
    if (cookiesEuOKButton2) {
      this.addListener(cookiesEuOKButton2);
    }
    if (cookiesEuOKButton3) {
      this.addListener(cookiesEuOKButton3);
    }
  },

  addListener: function(target) {
    // Support for IE < 9
    if (target.attachEvent) {
      target.attachEvent('onclick', this.setCookie);
    } else {
      target.addEventListener('click', this.setCookie, false);
    }
  },

  setCookie: function() {
    Cookies.set('cookie_eu_consented', true, { path: '/', expires: 365, secure: location.protocol === 'https:' });

    var container = document.querySelector('.js-cookies-eu');
    if (container) {
      container.parentNode.removeChild(container);
    }
    location.reload();
  }
};

(function() {
  function eventName(fallback) {
    return windowIsTurbolinked ? 'turbolinks:load' : fallback
  }

  var isCalled = false;

  function isReady() {
    // return early when cookiesEu.init has been called AND Turbolinks is NOT included
    // when Turbolinks is included cookiesEu.init has to be called on every page load
    if (isCalled && !windowIsTurbolinked) {
      return
    }
    isCalled = true;

    cookiesEu.init();
  }

  if (document.addEventListener) {
    return document.addEventListener(eventName('DOMContentLoaded'), isReady, false);
  }

  // Old browsers IE < 9
  if (window.addEventListener) {
    window.addEventListener(eventName('load'), isReady, false);
  } else if (window.attachEvent) {
    window.attachEvent(eventName('onload'), isReady);
  }
})();

function clearCookies() {
  document.cookie.split(';').forEach(cookie => document.cookie = cookie.replace(/^ +/, '').replace(/=.*/, `=;expires=${new Date(0).toUTCString()};path=/`));
}

window.accept_cookies = function () {
  Cookies.set('cookie_eu_consented', true, { path: '/', expires: 365, secure: location.protocol === 'https:' });
  location.reload();
}

window.no_accept_cookies = function () {
  clearCookies();
  Cookies.set('cookie_eu_consented', false, { path: '/', expires: 365, secure: location.protocol === 'https:' });
  // var container = document.querySelector('.js-cookies-eu');
  // container.parentNode.removeChild(container);
  location.reload();
}
