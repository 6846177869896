$(document).on('turbolinks:load', function(){
    $(".mega-dropdown.dropdown").hover(            
        function() {
            $('.mega-dropdown.dropdown-menu', this).not('.in .mega-dropdown.dropdown-menu').stop(true,true).slideDown("400");
            $(this).toggleClass('open');        
        },
        function() {
            $('.mega-dropdown.dropdown-menu', this).not('.in .mega-dropdown.dropdown-menu').stop(true,true).slideUp("400");
            $(this).toggleClass('open');       
        }
    );
});